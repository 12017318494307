<template>
  <b-card-code>
    <b-modal id="changePrivateMessage" title="Státusz állítása" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <b-form-group
            label="Megjegyzés"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Kire vonatkozik"
            label-for="target_name"
          >
            <b-form-input
              id="target_name"
              v-model="target_name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('changePrivateMessage')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="updatePrivateMessage()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-2">
        <h4>Szabadság státuszok</h4>
      </div>
      <div class="col-lg-10 text-right row justify-content-end">
        <button class="btn btn-primary col-lg-2 col-12" @click="$router.push('/freedays/list')">Összes nézet</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'leader_accepted'"
          class="text-center"
          :title="props.row.leader_name"
        >
          <feather-icon
            v-if="props.row.leader_accepted == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else-if="props.row.leader_accepted == 2"
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
          <feather-icon
            v-else
            icon="AlertCircleIcon"
            size="26"
            class="text-warning"
          />
        </div>

        <div v-else-if="props.column.field === 'archive'">
          <span v-if="props.row.archive">Arcivált</span>
          <span v-else>Aktív</span>
        </div>

        <div
          v-else-if="props.column.field === 'foreman_accepted'"
          class="text-center"
          :title="props.row.foreman_name"
        >
          <feather-icon
            v-if="props.row.foreman_accepted == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else-if="props.row.foreman_accepted == 2"
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
          <feather-icon
            v-else
            icon="AlertCircleIcon"
            size="26"
            class="text-warning"
          />
        </div>

        <div
          v-else-if="props.column.field === 'foreman_name'"
        >
          {{ props.row.foreman_name }}
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Neve',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Típusa',
          },
        },
        {
          label: 'Kivett szabadnapok',
          field: 'used_free_days',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: 'Igazolatlan napok',
          field: 'missed_days',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: 'Éves szabadnapok',
          field: 'starter_free_days',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: 'Kivett táppénz',
          field: 'used_sick_days',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: 'Éves táppénz',
          field: 'starter_sick_days',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés szövegben',
          },
        },
      ],
      employees: [],
      employees_create: [],
      rows: [],
      searchTerm: '',
      title: '',
      target_name: '',
      selected_id: '',
      foreman_list: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.listFreeDaysGlobal()
  },
  methods: {
    listFreeDaysGlobal() {
      axios({
        method: 'get',
        url: 'freeday/list/global',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
